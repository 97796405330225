<template>
  <div>
    <v-dialog
      :fullscreen="$vuetify.breakpoint.xsOnly"
      v-model="show"
      max-width="500px"
      max-height="800px"
      scrollable
      persistent
    >
      <div class="text-center" v-if="loading" style="min-height: 500px">
        <v-progress-circular
          :size="100"
          :width="10"
          style="
            text-align: center;
            padding-top: 20px;
            padding-bottom: 20px;
            margin-top: 200px;
          "
          color="#7253CF"
          indeterminate
        ></v-progress-circular>
      </div>

      <v-card background-color="#f2f2f2" v-else>
        <v-card-title
          class="py-0 pt-7"
          style="background-color: #7253cf; color: white"
        >
          <div class="d-flex px-1">
            <img src="@/assets/teamModalLogo.svg" height="85" width="100" />
          </div>
          <div class="ml-1 dialogTitle-text">
            {{ dialogTitle }}
          </div>
          <v-spacer></v-spacer>
          <v-icon
            text
            large
            color="white"
            style="
              cursor: pointer;
              position: relative;
              right: -15px;
              bottom: 40px;
            "
            @click="toggleNoteModal({ show: false });reload();"
            >mdi-close
          </v-icon>
        </v-card-title>
        <v-card-text class="pt-5">
          <v-form lazy-validation ref="volunteerForm">
            <v-row no-gutters justify="center" >
              <v-col cols="11" v-if="this.type!='view'">
              <v-autocomplete
                label="Category"
                v-model="note_category"
                outlined
                dense
                :items="categoryOptions"
                class="formFields"
                color="#7253CF"
              ></v-autocomplete>
            </v-col>
             <v-col cols="11" v-if="this.type!='view'">
                   <v-text-field
                  outlined
                  dense
                  v-model="subject"
                  label="Subject"
                  color="#7253CF"
                  class="formFields"
                >
                </v-text-field>

              </v-col>
              
              <v-col cols="11"  style="height: 400px" v-if="this.type!='view'">
                  <quillEditor v-model="note" :options="editorOption" style="height:70%"/>
              </v-col>
                 <v-col cols="11"  style="height: 400px" v-if="this.type=='view'">
                 <div v-html="note"></div>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="d-flex justify-end" v-if="(this.type === 'add'||'edit') && (this.type != 'view')">
          <v-btn
            class="dialogAction-btnText text-capitalize px-7"
            height="45"
            color="#38227A"
            :loading="formLoading"
            @click="submitForm"
       >
            <span>Submit</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import Axios from "@/api/BaseAxios";
import "quill/dist/quill.snow.css";
import { quillEditor } from "vue-quill-editor";
import {
NOTE_SECTION_POST_API,
NOTE_SECTION_GET_API,
NOTE_SECTION_PATCH_API,
} from "@/constants/APIUrls";
import RULES from "@/common/fieldRules";
export default {
  name: "NoteModal",
   components: {
   
    quillEditor
    },
  data() {
    return {
      rules: RULES,
      loading: false,
      formLoading: false,
      subject:"",
      note: "",
      note_by: "",
      note_category:"Event Support Notes",
       user_id: localStorage.getItem("user_id"),
          categoryOptions: [
        "Snapshot- Sales Summary",
        "Event Support Notes",
        "Event Support Wrap Up",
        "Assembly",
        "Intro",
        "Game Summary",
        "Ticket Giveaway"
      ],
    };
  },
  computed: {
    ...mapGetters({
      showGetters: "gameManagement/getNoteShow",
      selectGameData: "gameManagement/getGame",
    }),
    show: {
      get() {
        return this.showGetters;
      },
      set(value) {
        return this.toggleNoteModal({ show: value });
      },
    },
    type() {
      return this.$store.state.gameManagement.noteModal.type;
    },
    dialogTitle() {
      if (this.type === "add") return "Add Note";
       if (this.type === "edit") return "Edit Note";
         if (this.type === "view") return "View Note";
      else return "View Note ";
    },
    actionText() {
      if (this.type === "add") {
        return "Add Note";
      } else {
        return "Save";
      }
    },
    toastMessage(){
      if(this.type==="add"){
        return "Note Added Successfully."
      }else{
        return "Note Detail Updated."
      }
    }
  },
  watch: {
    show(value) {
      if (value) {
        this.openModal();
      } else {
        this.closeModal();
      }
    },
  },
  methods: {
    ...mapActions({
      toggleNoteModal: "gameManagement/toggleNoteModal",
      showToast: "snackBar/showToast",

    }),
    openModal() {
    if(this.type==="edit" ||this.type==="view" ){
    this.getDetail()
    }
    },
    closeModal() {
      this.loading = false;
      this.formLoading = false;
      this.subject = "";
      this.note = "";
       this.note_category = "";
      
    },
    getDetail() {
      const successHandler = (res) => {
      console.log(res.data)
      this.note=res.data.note_detail.note;
      this.note_category=res.data.note_detail.note_category;
      this.subject=res.data.note_detail.subject
        this.loading = false;
      };
      const failureHandler = (res) => {
        console.log(res);
        this.loading = false;
      };
      let formData = {};
      formData["note_id"] =
        this.$store.state.gameManagement.noteModal.noteID;
      Axios.request_GET(
        NOTE_SECTION_GET_API,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    submitForm() {
      this.formLoading = true;
      const successHandler = (res) => {
        console.log(res);
        this.formLoading = false;
        this.showToast({
          message:this.toastMessage,
          color:"s"
        })
        this.$emit("reload")
        location.reload()
        this.toggleNoteModal({show:false})
      };
      const failureHandler = (res) => {
        console.log(res);
        this.formLoading = false;
        this.showToast({
          message:res,
          color:"e"
        })
      };
      let formData = {};
      formData["game"] = this.$route.query.game_id;
      console.log(formData,this.selectGameData)
      formData["note"] = this.note;
        formData["note_category"] = this.note_category;
      formData["note_by"] = this.user_id;
      formData["subject"]=this.subject;
      if (this.type === "add") {
        Axios.request_POST(
          NOTE_SECTION_POST_API,
          formData,
          {},
          successHandler,
          failureHandler,
          false
        );
      } 
    else {
        formData["id"] =
          this.$store.state.gameManagement.noteModal.noteID;
        Axios.request_PATCH(
          NOTE_SECTION_PATCH_API,
          formData,
          {},
          successHandler,
          failureHandler,
          false
        );
      }
    },
    reload()
    {location.reload();}

  },
  mounted() {},
};
</script>
<style scoped>
* {
  font-family: Lato;
}
.formFields {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;

  /* identical to box height */

  color: #757575;
}
.dialogTitle-text {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 315%;
  color: #ffffff;
}

.dialogAction-btnText >>> span {
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 161.5%;
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: none !important;
}
.v-btn.dialogAction-btnText {
  border-radius: 10px;
}
.v-btn >>> span {
  letter-spacing: 0px;
}
</style>
